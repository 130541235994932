import { Attendance } from '@/stores/types/attendance'
import { AttendanceSlot } from '@/stores/types/attendanceslot'
import { AttendanceStatus } from '@/stores/types/attendancestatus'
import type { Student } from '@/stores/types/student'
import dayjs, { Dayjs } from 'dayjs'

interface Statable {
  attendances: Array<Attendance>
  // stats
  nbStudies: number
  nbAbsences: number
  nbPresent: number
  nbEvening: number
  nbAuthorizedToLeave: number
  nbTotalPossibleStudies: number
}

export class AttendanceStatusLabelsFactory {
  static getNewInstance() {
    return new Array<{
      value: AttendanceStatus
      title: string
      subtitle: string
      disabled: boolean
    }>(
      { value: AttendanceStatus.NONE, title: '', disabled: false, subtitle: '' },
      { value: AttendanceStatus.ABSENT, title: 'Absent·e', disabled: false, subtitle: '' },
      { value: AttendanceStatus.STUDY, title: 'Etude', disabled: false, subtitle: '' },
      { value: AttendanceStatus.PRESENT, title: 'Présent·e', disabled: false, subtitle: '' },
      {
        value: AttendanceStatus.EVENING_INDOOR,
        title: 'Soirée intérieure',
        disabled: false,
        subtitle: ''
      },
      {
        value: AttendanceStatus.EVENING_OUTDOOR,
        title: 'Soirée extérieure',
        disabled: false,
        subtitle: ''
      },
      {
        value: AttendanceStatus.AUTHORIZED_TO_LEAVE,
        title: 'Sortie Autorisée',
        disabled: false,
        subtitle: ''
      }
    )
  }
}

const OBSOLETE_SLOTS_BEFORE_DATE = dayjs('2025-01-12T00:00:00.000Z')

export class AttendanceUtils {
  static AttendanceStatusLabels = AttendanceStatusLabelsFactory.getNewInstance()

  static getAttendanceSlots(date: dayjs.Dayjs): Array<string> {
    const OBSOLETE_SLOTS = ['18:10', '20:15', '21:30']
    const SLOTS = ['18:00', '19:45', '21:45']

    return date.isBefore(OBSOLETE_SLOTS_BEFORE_DATE) ? OBSOLETE_SLOTS : SLOTS
  }

  static isObsoleteRules(date: dayjs.Dayjs) {
    return date.isBefore(OBSOLETE_SLOTS_BEFORE_DATE)
  }

  static getAttendanceSlotsNotWednesday(date: dayjs.Dayjs): Array<string> {
    return AttendanceUtils.getAttendanceSlots(date)
  }

  static getAttendanceSlotsWednesday(date: dayjs.Dayjs): Array<string> {
    const slots = AttendanceUtils.getAttendanceSlots(date)
    return [slots[1], slots[2]]
  }

  static getTitleForStatus(status: AttendanceStatus | undefined): string | undefined {
    return AttendanceUtils.AttendanceStatusLabels.find((x) => x.value === status)?.title
  }

  static updateStats(item: Statable) {
    let nbStudies = 0
    let nbPresent = 0
    let nbAbsences = 0
    let nbEvening = 0
    let nbAuthorizedToLeave = 0
    if (!item.attendances) {
      return
    }
    item.attendances.forEach((attendance) => {
      const dateAttendance = dayjs(attendance.date)
      // We don't count wednesday
      if (dateAttendance.day() !== 3) {
        attendance.slots.forEach((slot) => {
          // we don't count 3rd slot
          if (slot.startTime === AttendanceUtils.getAttendanceSlots(dateAttendance)[2]) return

          switch (slot.status) {
            case AttendanceStatus.PRESENT:
              nbPresent++
              break
            case AttendanceStatus.STUDY:
              nbStudies++
              break
            case AttendanceStatus.ABSENT:
              nbAbsences++
              break
            case AttendanceStatus.EVENING_INDOOR:
            case AttendanceStatus.EVENING_OUTDOOR:
              nbEvening++
              break
            case AttendanceStatus.AUTHORIZED_TO_LEAVE:
              nbAuthorizedToLeave++
              break
            default:
              break
          }
        })
      }
    })
    item.nbStudies = nbStudies
    item.nbAbsences = nbAbsences
    item.nbPresent = nbPresent
    item.nbEvening = nbEvening
    item.nbAuthorizedToLeave = nbAuthorizedToLeave
    item.nbTotalPossibleStudies = nbStudies + nbEvening
  }

  static studentAttendanceSlots(day: Dayjs | undefined): Array<string> {
    if (!day) return []
    return day.day() === 3
      ? AttendanceUtils.getAttendanceSlotsWednesday(day)
      : AttendanceUtils.getAttendanceSlotsNotWednesday(day)
  }

  static getStatus(
    student: Student,
    slotStartTime: string,
    day: Dayjs | undefined
  ): AttendanceSlot {
    const attendances = student.attendances.find(
      (x) => x.date?.format('YYYY-MM-DD') === day?.format('YYYY-MM-DD')
    )
    if (attendances) {
      return (
        attendances.slots.find((x) => x.startTime === slotStartTime) ||
        new AttendanceSlot(slotStartTime)
      )
    }
    return new AttendanceSlot(slotStartTime)
  }

  // ----------------------------------------------------------------------------------------------
  // OBSOLETE STATUSES ->

  // at 1st and 2nd slot, only studies can be globally set by default
  static obsoleteDefaultAttendanceStatus = AttendanceStatusLabelsFactory.getNewInstance().filter(
    (x) => [AttendanceStatus.STUDY, AttendanceStatus.ABSENT].includes(x.value)
  )!!

  // Present or Absent
  static obsoleteAttendanceStatusesPresentOrAbsent =
    AttendanceStatusLabelsFactory.getNewInstance().filter(
      (x) =>
        x.value === AttendanceStatus.NONE ||
        x.value === AttendanceStatus.ABSENT ||
        x.value === AttendanceStatus.PRESENT
    )
  // No present status
  static obsoleteAttendanceStatusesNoPresentStatus =
    AttendanceStatusLabelsFactory.getNewInstance().filter(
      (x) => x.value != AttendanceStatus.PRESENT
    )

  // No Present status AND disable evening with subtitle to explain
  static obsoleteAttendanceStatusesSlot1EveningAlreadyTakenInWeek =
    AttendanceStatusLabelsFactory.getNewInstance()
      .filter((x) => x.value != AttendanceStatus.PRESENT)
      .map((x) => {
        if (
          x.value === AttendanceStatus.EVENING_INDOOR ||
          x.value === AttendanceStatus.EVENING_OUTDOOR
        ) {
          x.disabled = true
          x.subtitle = 'Soirée déjà prise dans la semaine'
        }
        return x
      })

  // No Present status AND disable evening with subtitle to explain
  static obsoleteAttendanceStatusesStudyMandatory = AttendanceStatusLabelsFactory.getNewInstance()
    .filter((x) => x.value != AttendanceStatus.PRESENT)
    .map((x) => {
      if (
        x.value === AttendanceStatus.EVENING_INDOOR ||
        x.value === AttendanceStatus.EVENING_OUTDOOR
      ) {
        x.disabled = true
        x.subtitle = 'Etude obligatoire'
      }
      return x
    })

  // <- OBSOLETE STATUSES
  // ----------------------------------------------------------------------------------------------

  static emptyAttendanceStatus = AttendanceStatusLabelsFactory.getNewInstance().find(
    (x) => x.value === AttendanceStatus.NONE
  )!!

  static defaultAttendanceStatusSlot1 = AttendanceStatusLabelsFactory.getNewInstance().filter((x) =>
    [AttendanceStatus.PRESENT, AttendanceStatus.ABSENT].includes(x.value)
  )
  static defaultAttendanceStatusSlot2 = AttendanceStatusLabelsFactory.getNewInstance().filter((x) =>
    [AttendanceStatus.STUDY, AttendanceStatus.ABSENT].includes(x.value)
  )
  static defaultAttendanceStatusSlot3 = AttendanceStatusLabelsFactory.getNewInstance().filter((x) =>
    [AttendanceStatus.PRESENT, AttendanceStatus.ABSENT].includes(x.value)
  )

  static attendanceStatusSlot1 = AttendanceStatusLabelsFactory.getNewInstance().filter((x) =>
    [
      AttendanceStatus.PRESENT,
      AttendanceStatus.AUTHORIZED_TO_LEAVE,
      AttendanceStatus.ABSENT
    ].includes(x.value)
  )

  static attendanceStatusSlot2 = AttendanceStatusLabelsFactory.getNewInstance().filter((x) =>
    [
      AttendanceStatus.STUDY,
      AttendanceStatus.EVENING_INDOOR,
      AttendanceStatus.EVENING_OUTDOOR,
      AttendanceStatus.AUTHORIZED_TO_LEAVE,
      AttendanceStatus.ABSENT
    ].includes(x.value)
  )

  static attendanceStatusSlot2Wednesday = AttendanceStatusLabelsFactory.getNewInstance().filter(
    (x) =>
      [
        AttendanceStatus.STUDY,
        AttendanceStatus.AUTHORIZED_TO_LEAVE,
        AttendanceStatus.ABSENT
      ].includes(x.value)
  )

  // No Present status AND disable evening with subtitle to explain
  static attendanceStatusesSlot2EveningAlreadyTakenInWeek =
    AttendanceStatusLabelsFactory.getNewInstance()
      .filter((x) =>
        [
          AttendanceStatus.STUDY,
          AttendanceStatus.EVENING_INDOOR,
          AttendanceStatus.EVENING_OUTDOOR,
          AttendanceStatus.AUTHORIZED_TO_LEAVE,
          AttendanceStatus.ABSENT
        ].includes(x.value)
      )
      .map((x) => {
        if (
          x.value === AttendanceStatus.EVENING_INDOOR ||
          x.value === AttendanceStatus.EVENING_OUTDOOR
        ) {
          x.disabled = true
          x.subtitle = 'Soirée déjà prise dans la semaine'
        }
        return x
      })

  static attendanceStatusSlot3 = AttendanceStatusLabelsFactory.getNewInstance().filter((x) =>
    [AttendanceStatus.PRESENT, AttendanceStatus.ABSENT].includes(x.value)
  )

  static getAttendanceStatuses(
    student: Student,
    slotStartTime: string,
    day: Dayjs | undefined,
    rulesEnabled: Boolean = true
  ): Array<{ value: AttendanceStatus; title: string; disabled: boolean; subtitle: string }> {
    if (!day) return []

    const slots = AttendanceUtils.getAttendanceSlots(day)

    // OBSOLETE RULES ->
    if (AttendanceUtils.isObsoleteRules(day)) {
      // Slot 3, only present or absent statuses
      if (slotStartTime == slots[2]) {
        return AttendanceUtils.attendanceStatusSlot3
      }

      // If rules are disabled, return statuses without restrictions
      if (!rulesEnabled) {
        return AttendanceUtils.obsoleteAttendanceStatusesNoPresentStatus
      }

      // No evening at slots[0] for Students before "Terminal"
      // No evening at slots[1] on wednesday for everyone
      if (
        ((student.level < 2 || student.isLikeLevel0) && slotStartTime === slots[0]) ||
        (day?.day() === 3 && slotStartTime === slots[1])
      ) {
        return AttendanceUtils.obsoleteAttendanceStatusesStudyMandatory
      }
      // For level 0 => 1 evening by week maximum
      if ((student.level == 0 || student.isLikeLevel0) && student.nbEvening >= 1) {
        return AttendanceUtils.obsoleteAttendanceStatusesSlot1EveningAlreadyTakenInWeek
      }

      // Slot 1 and 2 without restrictions
      return AttendanceUtils.obsoleteAttendanceStatusesNoPresentStatus
    }
    // <- OBSOLETE RULES
    else {
      // If rules are disabled, return statuses without restrictions
      if (!rulesEnabled) {
        switch (slotStartTime) {
          case slots[0]:
            return AttendanceUtils.attendanceStatusSlot1
          case slots[1]:
            return AttendanceUtils.attendanceStatusSlot2
          case slots[2]:
            return AttendanceUtils.attendanceStatusSlot3
        }
      }

      // Rules enabled !
      switch (slotStartTime) {
        case slots[0]:
          // Slot 1 is always the same for everyone: Absent, Present, AuthorizedToLeave
          return AttendanceUtils.attendanceStatusSlot1
        case slots[2]:
          // Slot 3 is always the same for everyone: Present, Absent
          return AttendanceUtils.attendanceStatusSlot3
        case slots[1]:
        default:
          // Slot 2 depends !
          // No evening at Slot 2 on wednesday for everyone
          if (day?.day() === 3) {
            return AttendanceUtils.attendanceStatusSlot2Wednesday
          }

          // For level 0 and 1 and isLikeLevel0 at Slot 2 on other days => 1 evening by week maximum for Slot 2
          if (
            (student.level == 0 || student.level == 1 || student.isLikeLevel0) &&
            student.nbEvening >= 1
          ) {
            return AttendanceUtils.attendanceStatusesSlot2EveningAlreadyTakenInWeek
          }

          // Otherwise at Slot 2: Study, Evenings, AllowedToLeave, Absent
          return AttendanceUtils.attendanceStatusSlot2
      }
    }
  }

  static getDefaultAttendanceStatusesForSlot(
    slotStartTime: string,
    day: Dayjs
  ): Array<{ value: AttendanceStatus; title: string; disabled: boolean; subtitle: string }> {
    if (!day) return []

    const slots = AttendanceUtils.getAttendanceSlots(day)

    if (AttendanceUtils.isObsoleteRules(day)) {
      // SLOT 3 (21:30) -> NONE/PRESENT/ABSENT otherwise NONE/STUDY/ABSENT
      return [AttendanceUtils.emptyAttendanceStatus].concat(
        slotStartTime === slots[2]
          ? AttendanceUtils.defaultAttendanceStatusSlot3
          : AttendanceUtils.obsoleteDefaultAttendanceStatus
      )
    }
    // SLOT 1 (19:45) -> NONE/STUDY/ABSENT otherwise NONE/PRESENT/ABSENT
    return [AttendanceUtils.emptyAttendanceStatus].concat(
      slotStartTime === slots[1]
        ? AttendanceUtils.defaultAttendanceStatusSlot2
        : AttendanceUtils.defaultAttendanceStatusSlot3
    )!!
  }
}
