import type { AttendanceStatus } from './attendancestatus'

export class AttendanceSlot {
  startTime: string
  status: AttendanceStatus | undefined

  constructor(startTime: string) {
    this.startTime = startTime
  }
}
