import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { baseUrl } from './baseurl'
import type { AttendanceExportItem } from './types/attendanceexportitem'
import { AttendanceUtils } from '@/utils/attendance'
import type { AttendanceHistory } from './types/attendancehistory'
import type dayjs from 'dayjs'
import type { AttendanceStatus } from './types/attendancestatus'
import type { Floor } from './types/floor'
import type { Student } from './types/student'
import type { Attendance } from './types/attendance'

const urlAttendanceFromTo = `${baseUrl}/buildings/:buildingId/floors/:floorId/attendances?fromDate=:fromDate&toDate=:toDate`
const urlAttendanceSaveExport = `${baseUrl}/buildings/:buildingId/floors/:floorId/attendances?fromDate=:fromDate&toDate=:toDate&filteredClassname=:filteredClassname&detailsDisplayed=:detailsDisplayed`

export const useAttendancesStore = defineStore('attendances', () => {
  const attendanceExportItems: Ref<Array<AttendanceExportItem>> = ref([])
  const historyItems: Ref<Array<AttendanceHistory>> = ref([])

  async function loadFromDateToDate(
    buildingId: string,
    floorId: string,
    fromDate: string,
    toDate: string
  ) {
    attendanceExportItems.value = []
    const attendancesData = await axios.get(
      `${urlAttendanceFromTo.replace(':buildingId', buildingId).replace(':floorId', floorId).replace(':fromDate', fromDate).replace(':toDate', toDate)}`
    )
    attendanceExportItems.value = attendancesData.data as Array<AttendanceExportItem>

    // Also update stats
    attendanceExportItems.value.forEach((item) => {
      AttendanceUtils.updateStats(item)
    })
  }

  async function saveExport(
    buildingId: string,
    floorId: string,
    fromDate: dayjs.Dayjs,
    toDate: dayjs.Dayjs,
    filteredClassname: string,
    detailsDisplayed: boolean,
    attendances: Array<AttendanceExportItem>,
    buildingShortname: string,
    floorShortname: string
  ) {
    const resp = await axios.post(
      `${urlAttendanceSaveExport
        .replace(':buildingId', buildingId)
        .replace(':floorId', floorId)
        .replace(':fromDate', fromDate.format('YYYY-MM-DD'))
        .replace(':toDate', toDate.format('YYYY-MM-DD'))
        .replace(':filteredClassname', filteredClassname ? filteredClassname : '')
        .replace(':detailsDisplayed', detailsDisplayed.toString())}`,
      attendances
    )
    return resp.data as AttendanceHistory
  }

  async function loadHistoryItemsList(
    buildingId: string,
    floorId: string
  ): Promise<Array<AttendanceHistory>> {
    const attendancesData = await axios.get(
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}/attendances/history`
    )
    historyItems.value = attendancesData.data as Array<AttendanceHistory>
    return historyItems.value
  }

  async function loadHistoryItem(
    buildingId: string,
    floorId: string,
    itemId: string
  ): Promise<AttendanceHistory> {
    const attendancesData = await axios.get(
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}/attendances/history/${itemId}`
    )
    return attendancesData.data as AttendanceHistory
  }

  async function deleteHistoryItem(buildingId: string, floorId: string, itemId: string) {
    await axios.delete(
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}/attendances/history/${itemId}`
    )
    historyItems.value = historyItems.value.filter((item) => item.id !== itemId)
  }

  async function downloadHistoryPdf(
    buildingId: string,
    floorId: string,
    itemId: string,
    buildingShortname: string,
    floorShortname: string,
    filteredClassname: string,
    fromDate: dayjs.Dayjs,
    toDate: dayjs.Dayjs
  ) {
    const response = await axios.get(
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}/attendances/history/${itemId}/pdf`,
      { responseType: 'arraybuffer' }
    )

    const blob = new Blob([response.data], { type: 'application/pdf' })

    // create file link in browser's memory
    const href = URL.createObjectURL(blob)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.download = `APPEL du Bâtiment ${buildingShortname} ${floorShortname} ${filteredClassname ? 'Classe ' + filteredClassname : ''} Du ${fromDate.format('DD MMMM YYYY')} Au ${toDate.format('DD MMMM YYYY')}.pdf`
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    setTimeout(() => {
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }, 10000)
  }

  async function downloadAttendancePdf(
    buildingId: string,
    floorId: string,
    date: dayjs.Dayjs,
    buildingShortname: string | undefined,
    floorShortname: string | undefined
  ) {
    const response = await axios.get(
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}/attendances/empty-for-date?date=${date.format('YYYY-MM-DD')}`,
      { responseType: 'arraybuffer' }
    )
    const blob = new Blob([response.data], { type: 'application/pdf' })

    // create file link in browser's memory
    const href = URL.createObjectURL(blob)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.download = `APPEL Bâtiment ${buildingShortname} ${floorShortname} Du ${date.format('DD MMMM YYYY')}.pdf`
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    setTimeout(() => {
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }, 10000)
  }

  async function batchUpdate(
    floor: Floor,
    date: dayjs.Dayjs,
    slotStartTime: string,
    status: AttendanceStatus
  ) {
    await axios.put(
      `${baseUrl}/buildings/${floor.buildingRef}/floors/${floor.id}/attendances/${date.format()}/slots/${slotStartTime}`,
      {
        status: status
      }
    )
  }

  async function saveSlot(
    buildingId: string,
    floorId: string,
    student: Student,
    date: dayjs.Dayjs,
    slotStartTime: string,
    status: AttendanceStatus
  ): Promise<Attendance> {
    const resp = await axios.put(
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}/attendances/student/${student.id}/date/${date.format()}/slots/${slotStartTime}`,
      {
        status: status
      }
    )
    return resp.data as Attendance
  }

  return {
    attendanceExportItems,
    saveSlot,
    historyItems,
    loadFromDateToDate,
    saveExport,
    loadHistoryItemsList,
    loadHistoryItem,
    deleteHistoryItem,
    downloadHistoryPdf,
    downloadAttendancePdf,
    batchUpdate
  }
})
