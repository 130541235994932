<script setup lang="ts">
import dayjs from 'dayjs'
import { ref, type PropType, type Ref } from 'vue'
import StudentItem from '@/components/StudentItem.vue';
import '@/assets/table.css'
import { AttendanceUtils } from '@/utils/attendance';
import type { Room } from '@/stores/types/room'
import type { Student } from '@/stores/types/student';
import { useProfileStore } from '@/stores/profile';
import { useStudentsStore } from '@/stores/students';

const profileStore = useProfileStore()
const studentsStore = useStudentsStore()
const selectedStudent: Ref<Student | undefined> = ref()

defineProps({
  room: { type: Object as PropType<Room> },
  currentDay: { type: Object as PropType<dayjs.Dayjs> },
  disabled: { type: Boolean as PropType<Boolean> },
  rulesEnabled: { type: Boolean as PropType<Boolean>, default: true },
  attendanceSlots: { type: Array as PropType<Array<string>>, required: true },
})
defineEmits(['saveSlot'])

</script>

<style>
.stats {
  position: absolute;
  left: 100%;
  bottom: 0;
  font-size: 12px;
  font-style: italic;
}

.studentId {
  cursor: pointer;
}
</style>

<template>
  <v-card class="my-2">
    <v-card-title> Chambre: {{ room?.shortName }} </v-card-title>
    <v-card-text>
      <v-table>
        <thead>
          <tr>
            <th class="text-center fixed-column">Elève</th>
            <th v-if="currentDay?.day() != 3" class="text-center">{{ attendanceSlots[0] }}</th>
            <th class="text-center">{{ attendanceSlots[1] }}</th>
            <th class="text-center">{{ attendanceSlots[2] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in room?.students?.filter((x) => x.attendances)" :key="student.id">
            <v-dialog>
              <template v-slot:activator="{ props: studentDialog }">
                <td class="text-left text-blue fixed-column px-1 studentId" v-bind="studentDialog"
                  @click="selectedStudent = student">
                  {{ student.lastname }} {{ student.firstname }} <v-label class="text-green">{{ student.classname
                    }}</v-label>
                  <v-icon v-if="student.isRoomReferent" color="red">mdi-fire</v-icon>
                  <v-row no-gutters class="text-orange">
                    <v-label style="font-size: 11px" class="mr-1" v-if="student.isPai">
                      PAI
                    </v-label>
                    <v-label style="font-size: 11px" class="mr-1" v-if="student.isPap">
                      PAP
                    </v-label>
                    <v-label style="font-size: 11px" class="mr-1" v-if="student.isPps">
                      PPS
                    </v-label>
                    <v-label style="font-size: 11px; font-weight: bold;" class="mr-1 text-red"
                      v-if="student.isLikeLevel0">
                      Etude obligatoire
                    </v-label>
                  </v-row>
                </td>

              </template>

              <!-- Student modale -->
              <template v-slot:default="{ isActive }">
                <v-row justify="center" v-if="selectedStudent">
                  <v-card style="width: 600px; max-width: 90%; max-height: calc(100vh - 40px); overflow-y: auto;">
                    <v-card-title>
                      <v-row no-gutters>
                        {{ profileStore.current?.role === 'admin' ? "Modifier élève" : "Détails de l'élève" }}
                        <v-spacer></v-spacer>
                        <v-btn icon="mdi-close" variant="outlined" @click="() => {
                          selectedStudent = undefined
                          isActive.value = false
                        }"></v-btn>
                      </v-row>
                    </v-card-title>
                    <v-card-item>
                      <StudentItem :student="selectedStudent" :readonly="profileStore.current?.role !== 'admin'"
                        @update="(student: Student) => studentsStore.update(student)"></StudentItem>
                    </v-card-item>
                    <v-card-actions>
                      <v-row no-gutters>
                        <v-spacer></v-spacer>
                        <v-btn text="Fermer" @click="() => {
                          selectedStudent = undefined
                          isActive.value = false
                        }"></v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </template>
            </v-dialog>

            <td v-for="(slotStartTime, slotIndex) in AttendanceUtils.studentAttendanceSlots(currentDay)"
              :key="slotIndex" class="px-1">
              <v-select :disabled="disabled !== false" style="min-width: 152px"
                :items="AttendanceUtils.getAttendanceStatuses(student, slotStartTime, currentDay, rulesEnabled)"
                :model-value="AttendanceUtils.getStatus(student, slotStartTime, currentDay).status"
                :label="AttendanceUtils.getStatus(student, slotStartTime, currentDay).startTime"
                @update:model-value="$emit('saveSlot', student, slotStartTime, $event)">
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :subtitle="item.raw.subtitle ? item.raw.subtitle : ''"
                    :disabled="item.raw.disabled"></v-list-item>
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>
