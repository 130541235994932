<script setup lang="ts">
import { useClassnamesStore } from '@/stores/classnames'
import type { Student } from '@/stores/types/student'
import { gendersItems } from '@/stores/types/studentgenders'
import type { Ref } from 'vue'
import type { PropType } from 'vue'
import { onMounted, ref } from 'vue'

const prop = defineProps({
  student: { type: Object as PropType<Student>, required: true },
  readonly: { type: Boolean as PropType<Boolean>, default: true }
})
const emits = defineEmits(['update'])

const classnamesStore = useClassnamesStore()
const studentEdit = ref(prop.student)
const classnames: Ref<Array<string>> = ref([])

onMounted(async () => {
  await classnamesStore.all()
  classnames.value = classnamesStore.classnames.map(x => x.classname)
})

async function changedClassname(classname: string) {
  if (!studentEdit.value) return

  let level = classnamesStore.classnames.find((x) => x.classname === classname)?.level
  if (level === undefined) {
    level = -1
  }
  studentEdit.value.level = level
  studentEdit.value.isLikeLevel0 = studentEdit.value.level === 0
  emits('update', studentEdit.value)
}

async function updateGender(gender: string) {
  if (!studentEdit.value) return

  studentEdit.value.gender = gender
  emits('update', studentEdit.value)
}

</script>

<template>

  <v-card-subtitle class="text-primary">Identité</v-card-subtitle>
  <v-divider class="mb-2"></v-divider>

  <v-text-field :readonly="readonly === true" v-model="studentEdit.lastname" label="Nom de famille" variant="outlined"
    @update:model-value="$emit('update', student)"></v-text-field>
  <v-text-field :readonly="readonly === true" v-model="studentEdit.firstname" label="Prénom" variant="outlined"
    @update:model-value="$emit('update', student)"></v-text-field>
  <v-combobox :readonly="readonly === true" :clearable="false" v-model="studentEdit.classname" :items="classnames"
    label="Classe" @update:model-value="(item) => changedClassname(item)"></v-combobox>
  <v-select :readonly="readonly === true" :clearable="false" :items="gendersItems" item-title="label" item-value="value"
    :model-value="gendersItems.find(x => x.value == studentEdit.gender)" label="Genre"
    @update:model-value="(item: any) => updateGender(item)"></v-select>

  <v-expansion-panels class="my-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="text-primary">
        Informations élève
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row no-gutters justify="space-around">
          <v-switch :readonly="readonly === true" v-model="studentEdit.isPairMaster" label="Maître au pair"
            color="success" @update:model-value="$emit('update', student)"></v-switch>
          <v-switch :readonly="readonly === true" v-model="studentEdit.isLikeLevel0" label="Etude obligatoire"
            color="blue" @update:model-value="$emit('update', student)"></v-switch>
        </v-row>
        <v-row no-gutters justify="space-around">
          <v-switch :readonly="readonly === true" v-model="studentEdit.isPap" label="PAP" color="blue"
            @update:model-value="$emit('update', student)"></v-switch>
          <v-switch :readonly="readonly === true" v-model="studentEdit.isPai" label="PAI" color="blue"
            @update:model-value="$emit('update', student)"></v-switch>
          <v-switch :readonly="readonly === true" v-model="studentEdit.isPps" label="PPS" color="blue"
            @update:model-value="$emit('update', student)"></v-switch>
        </v-row>
        <v-textarea :readonly="readonly === true" v-model="studentEdit.comment" label="Notes" variant="outlined"
          @update:model-value="$emit('update', student)"></v-textarea>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-expansion-panels class="my-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="text-primary">
        Contacts
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row no-gutters>
          <v-text-field :readonly="readonly === true" v-model="studentEdit.phoneNumber" label="Numéro téléphone Elève"
            variant="outlined" prepend-inner-icon="mdi-phone"
            @update:model-value="$emit('update', student)"></v-text-field>
          <v-btn icon :disabled="!studentEdit?.phoneNumber" v-if="studentEdit.phoneNumber"
            :href="'tel:' + student!!.phoneNumber" class="ma-2" color="primary">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </v-row>

        <v-row no-gutters>
          <v-text-field :readonly="readonly === true" v-model="studentEdit.parent2PhoneNumber"
            label="Numéro téléphone Mère" variant="outlined" prepend-inner-icon="mdi-phone"
            @update:model-value="$emit('update', student)"></v-text-field>
          <v-btn icon :disabled="!studentEdit?.parent2PhoneNumber" v-if="studentEdit.parent2PhoneNumber"
            :href="'tel:' + student!!.parent2PhoneNumber" class="ma-2" color="primary">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-text-field :readonly="readonly === true" v-model="studentEdit.parent1PhoneNumber"
            label="Numéro téléphone Père" variant="outlined" prepend-inner-icon="mdi-phone"
            @update:model-value="$emit('update', student)"></v-text-field>
          <v-btn icon :disabled="!student?.parent1PhoneNumber" v-if="studentEdit.parent1PhoneNumber"
            :href="'tel:' + student!!.parent1PhoneNumber" class="ma-2" color="primary">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-text-field :readonly="readonly === true" v-model="studentEdit.otherContactPhoneNumber"
            label="Numéro téléphone 'Autre'" variant="outlined" prepend-inner-icon="mdi-phone"
            @update:model-value="$emit('update', student)"></v-text-field>
          <v-btn icon :disabled="!studentEdit?.otherContactPhoneNumber" v-if="studentEdit.otherContactPhoneNumber"
            :href="'tel:' + studentEdit!!.otherContactPhoneNumber" class="ma-2" color="primary">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
